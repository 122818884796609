.zoom {
  display: inline-block;

  overflow: hidden;
}

.zoom img div {
  display: block;
  transition: transform 0.7s; /* smoother zoom */
}
.zoom:hover img div {
  -webkit-transform: scale(1.2);
  transform: scale(1.1);
}
.zoom:hover {
  transition: transform 0.7s; /* smoother zoom */

  -webkit-transform: scale(1.2);
  transform: scale(1.1);
}
.zoom {
  transition: transform 0.7s; /* smoother zoom */

  display: block;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(3vh);
  visibility: hidden;
  transition: opacity 600ms ease-out, transform 300ms ease-out,
    visibility 600ms ease-out;
  will-change: opacity, transform, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}
.fadeInUp {
  animation: fadeInUp 1s ease backwards;
}
@keyframes fadeInUp {
  0% {
    transform: translate(0px, 100px);
    opacity: 0;
  }
  100% {
    transform: translate(0px, 0);
    opacity: 1;
  }
}
.ytp-title-enable-channel-logo .ytp-title-channel {
  display: none !important;
}
.ytp-title-enable-channel-logo .ytp-title-channel {
  display: none !important;
}
.ytp-title-enable-channel-logo .ytp-title {
  display: none !important;
}
.ytp-title {
  display: none !important;
}
.ytp-title-text {
  display: none !important;
}
.ytp-title-text {
  display: none;
}
.toastui-editor-contents p {
  font-size: 16px;
}
.toastui-editor-contents img {
  max-width: 600px !important;
}
#source {
  font-size: 14px;
  font-weight: 400;
  color: #000;
  margin-top: 30px !important;
}
@media (max-width: 900px) {
  .toastui-editor-contents p {
    font-size: 13px;
  }
  .toastui-editor-contents img {
    max-width: 100% !important;
  }
}
