body {
  height: 100vh;
}

#two {
  background: #000000;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 124px;
  text-align: center;
  font-family: "NanumBarunGothic";
}

.box {
  height: 200px;
  overflow: hidden;
  position: relative;
}

#two p {
  float: left;
}

.ull {
  float: right;
  margin: 0;
  padding: 10px 0 10px;
}
#two ul {
  float: right;
  margin: 0 10px;
  padding: 10px 0 10px;
}
.is-visiblea {
  float: right;
  margin: 10px 0 10px;

  padding: 0;
  -webkit-animation: scrollUp 2s ease-in-out 0.5s 1 forwards;
  animation: scrollUp 2s ease-in-out 0.5s 1 forwards;
}

#two ul li {
  opacity: 1;
  height: 170px;
  padding: 10px 10px;
  list-style: none;
  font-family: "NanumBarunGothic";
  letter-spacing: -3px;
}

@-webkit-keyframes scrollUp {
  from {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(-90%);
    transform: translateY(-90%);
  }
}

@keyframes scrollUp {
  from {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(-90%);
    transform: translateY(-90%);
  }
}

@-webkit-keyframes scrollUp2 {
  from {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(-90%);
    transform: translateY(-90%);
  }
}

@keyframes scrollUp2 {
  from {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(-90%);
    transform: translateY(-90%);
  }
}

#two2 {
  background: #000000;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 36px;
  text-align: center;
  line-height: 1;

  font-family: "NanumBarunGothic";
}

.box2 {
  height: 55px;
  overflow: hidden;
  position: relative;
}

#two2 p {
  float: left;
}

#two2 ul li {
  opacity: 1;
  height: 50px;
  padding: 10px 10px;
  list-style: none;
  font-family: "NanumBarunGothic";
  letter-spacing: -1px;
}
.is-visiblea2 {
  float: right;
  margin: 10px 0 10px;

  padding: 0;
  -webkit-animation: scrollUp2 2s ease-in-out 0.5s 1 forwards;
  animation: scrollUp2 2s ease-in-out 0.5s 1 forwards;
}

@-webkit-keyframes scrollUp3 {
  from {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(-90%);
    transform: translateY(-90%);
  }
}

@keyframes scrollUp3 {
  from {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(-90%);
    transform: translateY(-90%);
  }
}

#two3 {
  background: #000000;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 30px;
  text-align: center;
  line-height: 1;

  font-family: "NanumBarunGothic";
}

.box3 {
  height: 45px;
  overflow: hidden;
  position: relative;
}

#two3 p {
  float: left;
}

#two3 ul li {
  opacity: 1;
  height: 50px;
  padding: 10px 10px;
  list-style: none;
  font-family: "NanumBarunGothic";
  letter-spacing: -3.2px;
}
.is-visiblea3 {
  float: right;
  margin: 10px 0 10px;

  padding: 0;
  -webkit-animation: scrollUp2 2s ease-in-out 0.5s 1 forwards;
  animation: scrollUp2 2s ease-in-out 0.5s 1 forwards;
}

.box::before {
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  content: "";
  height: 90px;
  position: absolute;
  background: linear-gradient(180deg, #000, rgba(255, 142, 113, 0));
}
.box::after {
  left: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  content: "";
  height: 30px;
  position: absolute;
  background: linear-gradient(180deg, rgba(255, 142, 113, 0), #000);
}

.black {
  flex-grow: 1;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 9999999999;
  background-color: black;
}
.backtransition {
  -webkit-transition: background-color 1000ms linear;
  -moz-transition: background-color 1000ms linear;
  -o-transition: background-color 1000ms linear;
  -ms-transition: background-color 1000ms linear;
  transition: background-color 1000ms linear;
  background-color: white;
}
.white {
  flex-grow: 1;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 9999999999;
  background-color: white;
}
